<template>
  <v-app id="inspire">


    <navbar></navbar>

    <drawer></drawer>

    <v-main>
      <v-container
          class="py-8 px-6"
          fluid
      >
        <router-view/>
      </v-container>

    </v-main>
  </v-app>
</template>

<script>
import navbar from "./components/Navbar";
import drawer from "./components/Drawer";
import {mapGetters} from "vuex";

export default {
  name: 'app',
  components: {
    navbar,
    drawer
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  },
  data: () => ({
    cards: ['Today', 'Yesterday'],
    drawer: null,
    links: [
      ['mdi-inbox-arrow-down', 'Inbox'],
      ['mdi-send', 'Send'],
      ['mdi-delete', 'Trash'],
      ['mdi-alert-octagon', 'Spam'],
    ],
  }),
}
</script>

