<template>

  <form>
    <v-text-field
        v-model="email"
        :error-messages="emailErrors"
        label="E-mail"
        required
        @input="$v.email.$touch()"
        @blur="$v.email.$touch()"
    ></v-text-field>
    <v-text-field
        v-model="password"
        :error-messages="passwordErrors"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        label="Password"
        required
        @input="$v.password.$touch()"
        @blur="$v.password.$touch()"
    ></v-text-field>
    <v-checkbox
        v-model="checkbox"
        :error-messages="checkboxErrors"
        label="Do you agree?"
        required
        @change="$v.checkbox.$touch()"
        @blur="$v.checkbox.$touch()"
    ></v-checkbox>

    <v-btn
        class="mr-4"
        @click="submit"
    >
      submit
    </v-btn>
    <v-btn @click="clear">
      clear
    </v-btn>
  </form>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'


export default {
  mixins: [validationMixin],

  validations: {
    password: { required },
    email: { required, email },
    checkbox: {
      checked (val) {
        return val
      },
    },
  },
  computed: {
    checkboxErrors () {
      const errors = []
      if (!this.$v.checkbox.$dirty) return errors
      !this.$v.checkbox.checked && errors.push('You must agree to continue!')
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Password is required.')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
  },
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      error: null,
      password: '',
      showPassword: false,
      email: '',
      checkbox: false,
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
          .then(() => {
            this.$router.replace({ name: "Dashboard" });
          })
          .catch((err) => {
            this.error = err.message;
          });
    },
    clear () {
      this.$v.$reset()
      this.password = ''
      this.email = ''
      this.checkbox = false
    }
  }
};
</script>
