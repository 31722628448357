import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null
    },
    drawer: {
      status: false
    }
  },
  getters: {
    user(state){
      return state.user
    },
    drawer(state){
      return state.drawer
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    CHANGE_DRAWER_STATUS(state) {
      state.drawer.status = !state.drawer.status;
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email
        });
      } else {
        commit("SET_USER", null);
      }
    },
    switchDrawerStatus({ commit }) {
        commit("CHANGE_DRAWER_STATUS");
    }
  }
})
