<template>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-8">
        <div className="card">
          <div className="card-header">Dashboard</div>
          <div className="card-body">
            <div v-if="user" className="alert alert-success" role="alert">You are logged in!</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from "vuex";
  export default {
  computed: {
  // map `this.user` to `this.$store.getters.user`
  ...mapGetters({
  user: "user"
})
}
};
</script>