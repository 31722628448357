import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCTkrnbkXFCb61j4jJU03iWZc6QPoUPG-w",
  authDomain: "andre89-1e4e9.firebaseapp.com",
  databaseURL: "https://andre89-1e4e9-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "andre89-1e4e9",
  storageBucket: "andre89-1e4e9.appspot.com",
  messagingSenderId: "223530530502",
  appId: "1:223530530502:web:5cf8a078ed9f3ad1e349c7",
  measurementId: "G-V63EKQYFVC"
}


initializeApp(firebaseConfig);

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    // console.log(user)
    store.dispatch("fetchUser", user);
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
