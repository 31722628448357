<template>
  <h1> Formazione </h1>
</template>

<script>

export default {
  name: 'Educations',

  components: {
  },
}
</script>
