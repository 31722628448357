<template>
  <div>
    <v-app-bar
        color="blue darken-3 accent-4"
        dense
        dark
        app
    >
      <v-app-bar-nav-icon @click="switchDrawerStatus()"></v-app-bar-nav-icon>

      <v-toolbar-title>Andre89.it</v-toolbar-title>

      <v-spacer></v-spacer>



      <template v-if="user.loggedIn">
        <v-btn @click="signOut()" icon>
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>
      <template v-else>

        <v-btn @click="goToLoginPage()" icon>
          <v-icon>mdi-login</v-icon>
        </v-btn>

        <v-btn @click="goToRegisterPage()" icon>
          <v-icon>mdi-account-plus</v-icon>
        </v-btn>
      </template>

    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuth, signOut } from "firebase/auth";
export default {
  computed: {
    ...mapGetters({
// map `this.user` to `this.$store.getters.user`
      user: "user",
      drawer: "drawer"
    })
  },
  methods: {
    signOut() {

      const auth = getAuth();
      signOut(auth).then(() => {
        this.$router.replace({
          name: "home"
        });
      })

    },
    goToLoginPage() {
      this.$router.push('login')
    },
    goToRegisterPage() {
      this.$router.push('register')
    },
    switchDrawerStatus(){
      this.$store.dispatch('switchDrawerStatus');
    }
  }
};
</script>
